@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
}

html {
     scroll-behavior: smooth;
}

body,
button,
input,
textarea {
     font-family: Poppins;
     font-size: 1rem;
}

/* body {
     background-color: #FAFAFA;
     color: #757575;
} */

h1,
h2,
h3 {
     /* color: #333333; */
     font-weight: 600;
}

ul {
     list-style: none;
}

a {
     text-decoration: none;
}

button {
     cursor: pointer;
     border: none;
     outline: none;
}

img {
     max-width: 100%;
     height: auto;
}

.show {
     bottom: 3rem;
}

/* border-t-4 py-0.5 border-titleColor rounded-md */
/* .active-tab {
     border-top: 2px solid #000000;
     border-bottom: 2px solid #000000;
     padding: 0.125rem 0;
     border-radius: 0.375rem;
} */

/* Button */
.button {
     display: inline-block;
     /* background-color: #333333;
     color: white; */
     padding: 1.25rem 2rem;
     border-radius: 1rem;
     font-weight: 500;
}

.button--flex {
     display: inline-flex;
     align-items: center;
}

.profile-image {
     border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
}



@media (max-width: 768px) {

     body,
     button,
     input,
     textarea {
          font-size: 0.938rem;
     }

     .nav_menu {
          position: fixed;
          bottom: -100%;
          left: 0;
          width: 100%;
          padding: 2rem 1.5rem 4rem;
          box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
          border-radius: 1.5rem 1.5rem 0 0;
          transition: .3s;
     }

     .show_menu {
          bottom: 0;
     }
}

@media (max-width: 350px) {
     .nav_menu {
          padding: 2rem 0.25rem 4rem;
     }
}